body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e2e2e2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas{
  height: 100vh;
  width: 100vh;
}

.button-sections {
  display: flex;
}

/* #show_syn_ant {
  display: none;
} */
#antonyms, #synonyms {
  padding: 5px;
  background: rgb(70, 238, 70) !important;
}
#evone, #testBtn {
  background: rgb(70, 238, 70) !important;
  color: #000 !important;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

#user-vone-inputs {
  margin-top: 1rem;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 10px;
}

#uinput {
  font-weight: bold;
}

#canswer {
  margin-top: 10px;
  font-weight: bold;
}

#user-score {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

#rightcount {
  background: green;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  margin-right: 1rem;
}

#wrongcount {
  background: red;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
}
p.timer {
  font-weight: bold;
  color: black;
}
span#tcount {
  color: red;
  font-size: 24px;
}

#user-word-inputs {
  margin-top: 1rem;
}

#show_syn, #show_ant {
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 1rem;
  background: yellow;
  display: none;
}
.block {
  display: block !important;
}
.hidden {
  display: none !important;
}
